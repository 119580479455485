@media screen and (max-width: 900px) {
  body {
    overflow-x: hidden;
  }

  #centerconteud {
    width: 90% !important;
    margin-left: 5% !important;
    font-family: "Century" !important;
  }

  #sowp {
    padding-top: 12px;
    padding-bottom: 12px;
    height: 30px !important;
    width: 100%;
    position: fixed !important;
    z-index: 15 !important;
    display: block !important;
    background:rgb(255, 255, 255) !important;
     border-bottom: 1px solid #1e8b27 !important;

   
  }

  #btn_m {
    height: 30px !important;
    width: 10px !important;
    border-style: none !important;
    font-size: 18pt !important;
    background:rgb(255, 255, 255) !important;
    color: #1f1f1f;
  }

  #mobiliul {
    padding-top: 10px !important;
    margin-left: 10px !important;
    height: auto !important;
  }

  #btncont {
    text-align: center !important;
    font-size: 18pt !important;
    margin-top: 8px !important;
  }

  #mge {
    margin-left: 10px;
  }

  #btnflex {
    display: flex;
  }

  #wpt {
    position: absolute;
    color: #fff;
    display: block !important;
    width: 60%;
    margin-left: 20%;
    height: 50px;
    background: #29a71a !important;
    margin-top: 500px;
    border-radius: 8px;
    border: 1px solid;
    border-color: #29a71a;
    box-shadow: 2px 2px 2px #888888;
    z-index: 7;
  }

  #wpt:hover {
    color: #fff;
    position: absolute;
    z-index: 7;
    display: block !important;
    width: 60%;
    margin-left: 20%;
    height: 50px;
    background: #38e625 !important;
    margin-top: 500px;
    border-radius: 8px;
    border: 1px solid;
    padding: 10px;
    box-shadow: 2px 2px 2px #888888;
  }

  #reftext {
    display: block !important;
    font-size: 10pt;
    color: #c7c7c7;
  }

  #componentewpt {
    width: 70% !important;
    margin-left: 15px !important;

    color: #fff !important;
    position: absolute !important;
    z-index: 6 !important;
    background: #0077ff;
    margin-top: 700px;
    display: block !important;
  }

  #textvalorL {
    display: block !important;
    height: max-content !important;
  }

  #valoresL {
    height: max-content !important;
  }

  #missaoL {
    width: 100% !important;
  }

  #texto {
    margin-top: 10px !important;
    font-size: 14pt !important;
    line-height: 1.3 !important;
    color: #464646;
  }

  #mvv {
    width: 100% !important;
    flex-wrap: wrap !important;
  }

  h2 {
    font-size: 18pt !important;
    line-height: 1.1 !important;
    color: #000000 !important;
  }

  #missaovalores {
    display: block !important;
  }

  #itens {
    text-align: justify !important;
    height: 400px !important;
    overflow: auto !important;
  }

  #logoMobile {
    padding-top: 30px;
    display: block !important;
    margin-bottom: 10px !important;
  }

  #listservic {
    height: 400px !important;
    overflow: auto;
  }

  #m2 {
    display: none;
  }

  #men {
    display: none;
  }

  #anime {
    position: absolute !important;
    margin-top: 0 !important;
    width: 100% !important;
    height: max-content !important;
    margin-left: 0 !important;
    border-radius: 0 !important;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7091211484593838) 24%,
      rgba(0, 0, 0, 0.47102591036414565) 64%,
      rgba(0, 0, 0, 0) 100%
    ) !important;
  }

  #initial {
    top: 0 !important;
    left: 0 !important;
    background-image: url("../src/styles/image/back.jpg");
    background-size: cover;
    height: 100vh !important;
    width: 100vw;
  }

  #men {
    display: none !important;
  }

  #animetext {
    padding-top: 80px !important;
    text-transform: uppercase;
    font-weight: 900 !important;
    text-align: center !important;
    margin-left: 10px !important;
    line-height: 1.5 !important;
    font-size: 18pt !important;
  }
} /* Estilo para dispositivos moveis*/

#efect {
  position: absolute;
  height: 400px !important;
  width: 100% !important;

  background: rgb(126, 15, 15) !;
}

#logoMobile {
  display: none;
}

circle {
  color: #007000 !important;
  cx: 12;
  cy: 12;
  r: 12;
}

#btnvertical {
  background: transparent !important;
  font-size: 12pt !important;
  border-style: none !important;
  box-shadow: none !important;
}

#passo {
}

#btnvertical:hover {
  background: transparent !important;
  font-size: 12pt !important;
  border-style: none !important;
  box-shadow: none !important;
}

#boxt {
  line-height: 1.5 !important;
  font-family: "Century" !important;

  width: 100% !important;
}

#foto {
  filter: grayscale(1) !important;
}

@font-face {
  font-family: Century;
  src: url(../src/styles/tipografia/century-gothic.ttf);
}

* {
  top: 0;
  left: 0;
  bottom: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#m2 {
  margin-top: -23px !important;
  margin-left: 65%;
  text-transform: uppercase !important;
}

#componentewpt {
  display: none;
}

#wpt {
  display: none;
}
#Reg {
  font-size: 15pt;
  color: #004700;
}

li {
  margin-bottom: 10px;
  line-height: 1.5;
}

li:before {
  content: "\2022";
  color: #008080;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

#imageBackground {
  width: 100%;
}
body {
  font-family: "Century";
}
#texto {
  margin-top: 80px;
  font-size: 16pt;
  line-height: 1.8;
  text-align: justify;
  width: 100%;
  font-weight: 300;
}
#texto2 {
  font-size: 14pt !important;
  line-height: 1.3 !important;
  color: #464646;
  text-align: justify !important;
}

#contato {
  background: #dddddd;
}

#nome {
  color: rgb(48, 48, 48) !important;
  font-family: "Century";
}
layer #tm {
  color: #0077ff;
}
#logoHeader {
}

#animetext {
  text-transform: uppercase;
  font-weight: 900;
  padding-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  font-size: 23pt;
}

.css-1wlk0hk-MuiAvatar-root {
  margin-left: 100px !important;
  width: 180px !important;
  height: 180px !important;
  filter: grayscale(10);
}
#anime {
  border-radius: 30px;
  height: max-content;
  width: 70%;
  margin-left: 15%;
  margin-top: 20%;
  position: absolute;
  text-align: center;

  z-index: 5;
  font-weight: bold;
  color: #fff;
  background: #000000c2;
}

#qsomos {
  width: 100%;
}

.css-18st3fs-MuiButtonBase-root-MuiButton-root {
  color: #222222 !important;
  font-size: 12pt !important;
  text-transform: lowercase !important;
}

.css-18st3fs-MuiButtonBase-root-MuiButton-root:hover {
  font-size: 12pt !important;
  color: #646464 !important;
  font-size: 12pt !important;
  text-transform: lowercase !important;
}

#hovernull {
  font-size: 8pt;
}

#hovernull:hover {
  font-size: 8pt;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
  width: 350px !important;
}

#layer1 {
  width: 70%;
  margin-left: 15%;
  text-align: center;
  position: absolute;
  z-index: 4 !important;
  font-size: 20pt !important;
  margin-top: -450px;
  font-weight: 900;
  color: #5c5c5cbb;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 900;
}
#textos {
  margin-left: 8px !important;
  margin-right: 5px !important;
  text-indent: 1.2;
}
strong {
}

#missaovisaovalor {
  background: #fff !important;
}

#logowhatsapp {
  margin-top: -50px !important;
  margin-left: 40px;
}

#cvFormat {
  font-family: "Century";
  text-align: start !important;
  color: #0f0f0f;
  font-size: 12pt !important;
  width: 240px !important;
}
#cvw {
  display: flex;
  justify-content: space-evenly;
}
#flexc {
  display: flex !important;
}

#colun1,
#colun2,
#colun3 {
  margin-left: 20px;

  width: 30.6%;
  margin-left: 2%;
  padding-top: 2%;
  background: #1e8b2759;
  text-align: justify;
  font-weight: bold;
  color: #000000;
}

#windowT{
  font-family: "Century" !important;
}

#estudos {
  width: 100%;
  text-align: justify;
}

#videoback {
  width: 100% !important;
}

#mvv {

  width: 100%;
  justify-content: space-between;
  display: flex;
}
h2 {
  text-align: center;
  font-family: "Century" !important;
}
#imgLog {
  width: 220px !important;
}
#card1,
#card1,
#card1 {
  margin-left: 50px;
  height: 400px !important;
}
#w50 {
  width: 50px !important;
  margin-left: 30px;
}

#text_btn_w {
  margin-top: 15px;
  font-weight: 400;
}

#initial {
  left: 0 !important;
  background-image: url("../src/styles/image/back.jpg");
  background-size: cover;
  height: 100vh !important;
  width: 100%;
}

#LVMLOGO {
  margin-top: 100px !important;
}

#arx {
  font-size: 20pt;
}
#arx1 {
  font-size: 20pt;
}
#flexDI {
  display: flex;
}

#msg {
  font-size: 14pt !important;
}

#whats {
  height: 60px;
  width: 360px;
  border-style: none;
  background-color: #1bd741;
  color: #fff;
  position: absolute;
  margin-top: 830px;
  margin-left: 720px;
  border-radius: 20px;
  font-size: 14pt;
}

#flexC {
  margin-top: 50px;
  display: flex;
}

#conhecanossaforma {
  width: 100% !important;
}

#menu {
  z-index: 10 !important;
  height: 80px;
  width: 100%;
}

button {
  cursor: pointer;

  background: transparent;
  border-style: none;
  color: #007000;
  font-size: 16pt;
}

#home {
  color: #004d00 !important;
  cursor: pointer;
  font-size: 1vw;
}

#home:hover {
  cursor: pointer;
}

#text {
  width: 700px !important;
}

#rest {
  color: #028102 !important;
  cursor: pointer;
  font-size: 1vw !important;
}

p {
  text-align: justify;
  font-size: 15pt;
  font-family: "century";
}

#missaovalores {
  display: flex;
  justify-content: space-evenly !important;
  width: 100%;
}

#textmissaoL {
  text-align: justify;
  color: #fff;

  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

#textvisaoL {
  text-align: justify;
  color: #fff;

  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

#textvalorL {
  justify-content: space-between !important;
  text-align: inherit !important;
  color: #fff;
  display: flex;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

#valoresL {
  color: #727272;
  font-size: 13pt;
  background: #000000c2;
  width: 100% !important;
  border-radius: 10px;
  border-radius: 10px;
  border: 0.5px solid;
  border-color: #1e8b27;
  box-shadow: 5px 5px 5px #a8a8a8;
}

#visaoL {
  font-size: 13pt;
  background: #1e8b27;
  width: 99% !important;
  border-radius: 10px;
  border: 0.5px solid;
  border-color: #1e8b27;
  box-shadow: 5px 5px 5px #a8a8a8;
}

#missaoL {
  margin-left: 0.5%;
  font-size: 13pt;
  background: #1e8b27;
  width: 99% !important;
  border-radius: 10px;
  border: 0.5px solid;
  border-color: #1e8b27;
  box-shadow: 5px 5px 5px #a8a8a8;
}

ul {
  overflow: auto !important;
  height: 400px;
}

.css-1f5ro5o-MuiButtonBase-root-MuiButton-root:hover {
  background-color: transparent !important;
  color: #017c01 !important;
  border-style: none !important;
  box-shadow: none !important;
  font-size: 13pt !important;
}

#rest:hover {
  color: #00a9f7 !important;
  cursor: pointer;
}

li:before {
  display: none;
}

#RIU {
  font-size: 1.35vw;
  margin-top: -36px;
  margin-left: 100px;
}

button:hover {
  cursor: pointer;

  background: transparent;
  border-style: none;
  color: #1e8b27;
  font-size: 16pt;
}

#center {
  padding-top: 20px;
  height: 80px;
  margin-left: 62%;
}

#men {
  position: fixed;
  width: 100%;
  height: 80px !important;
  background: #ffffff;
  z-index: 11 !important;
  border-bottom: 1px solid #1e8b27;
}

#missao {
  font-size: 18pt;
  width: 100%;
}

#quemsomos {
  background: #dddddd;
}

#header_Basick {
  width: 100%;
  background: #1e8b27;
  height: 5px;
  color: #fff;
  text-align: center;
  font-size: 20pt;
  border-radius: 3px;
}

#text_static {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: justify;
}

#visao {
  font-size: 18pt;
  width: 100%;
}

#valores {
  font-size: 18pt;
  width: 100%;
}

#telcont{
  font-size: 18pt !important;
  font-weight: 900 !important;

  
}

#centerconteud {
  width: 90%;
  margin-left: 5%;
  font-family: "Century" !important;
}

#mobiliul {
}

.descktopUL {
  display: flex !important;
  text-align: center;
  font-size: 12pt !important;
}

.refclass {
  display: none;
}

.css-9l3uo3 {
  font-size: 14pt !important;
  font-family: "Century" !important;
}

#lts {
  font-size: 13pt;
  line-height: 1.6;
  text-align: justify;
  width: 100%;
  font-weight: 300;
}

#ty {
}

#cvlibg {
  color: #004700;
}

#panel1bh-header {
  font-family: "Century" !important;
  color: #131313;
  text-align: justify !important;
}

#mg {
  margin-top: 50px;
  background: transparent;
}

#px50 {
  height: 20px;
  color: transparent;
}

#contact {
  background: #dddddd;
}

#criado {
  text-align: center;
  font-size: 9pt;
  color: #acacac;
  font-family: monospace;
  background: #000000;
}

#sowp{
  display: none;
}